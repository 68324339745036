html {
  position: relative;
  height: 100%;
  overflow: hidden;
}

body {
  position: relative;
  height: 100%;
  overflow: hidden;
}

div#root{
  position: relative;
  height: 100%;
  overflow: hidden;
}

/* 参考: https://github.com/Hacker0x01/react-datepicker/issues/1252#issuecomment-364888148 */
.react-datepicker-popper {
  z-index: 3;
}
